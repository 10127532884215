<template>
  <div>
    <b-container>
      <p class="text-right">
        <b-button size="sm" @click="selectAllRows" class="m-2"
          >Select all</b-button
        >
        <b-button size="sm" @click="clearSelected">Clear selected</b-button>
      </p>
      <b-table
        ref="selectableTable"
        selectable
        @row-selected="onRowSelected"
        class="mt-4"
        :items="items"
        :fields="fields"
        v-model="visibleRows"
      >
        <template slot="top-row">
          <td colspan="4" class="text-right">Total Production Lot</td>
          <td>
            <b-input
              type="number"
              v-model="total_production"
              @change="calculateQuantity"
              @keyup="calculateQuantity"
            />
          </td>
          <td>
            <b-button size="sm" @click="overwriteCustomQuantity"> >> </b-button>
          </td>
          <td>
            {{ totalCustomQuantity }}
            <span
              v-if="
                totalCustomQuantity > 0 &&
                  (differenceToTotalProduction > 0 ||
                    differenceToTotalProduction < 0)
              "
              >(<b
                :class="
                  differenceToTotalProduction > 0
                    ? 'text-success'
                    : 'text-danger'
                "
                >{{ differenceToTotalProduction }}</b
              >)</span
            >
          </td>
        </template>
        <template #cell(revenue_amount)="data">
          {{ currencyFormatter(data.item.revenue_amount) }}
        </template>
        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
            <span class="sr-only">Not selected</span>
          </template>
        </template>
        <template #cell(custom_quantity)="data">
          <b-form-input
            type="number"
            v-model="data.item.custom_quantity"
            class="w-50"
          />
        </template>
      </b-table>
    </b-container>
  </div>
</template>

<script>
//TODO nel servizio aggiungere i clienti business (e possibilmente chi riceve i campioni [quindi mettendoli business??]) a zero,
//TODO importante se si tratta di clienti nuovi
export default {
  name: "Raxo",
  data() {
    return {
      total_production: null,
      items: [
        {
          revenue_amount: 121345.34,
          company: "Tecnomodel Srl",
          revenue_percentage: 22.23,
          custom_quantity: 0
        },
        {
          revenue_amount: 121345.34,
          company: "Trenogheno",
          revenue_percentage: 11.57,
          custom_quantity: 0
        },
        {
          revenue_amount: 121345.34,
          company: "Treni&treni",
          revenue_percentage: 9.12,
          custom_quantity: 0
        }
      ],
      fields: [
        "selected",
        "revenue_amount",
        "company",
        {
          key: "revenue_percentage",
          label: "Revenue percentage (%)"
        },
        "calculated_quantity",
        ">>",
        "custom_quantity"
      ],
      selected: [],
      visibleRows: []
    };
  },
  computed: {
    totalCustomQuantity() {
      return this.visibleRows.reduce((accum, item) => {
        return accum + parseInt(item.custom_quantity);
      }, 0);
    },
    differenceToTotalProduction() {
      return this.total_production - this.totalCustomQuantity;
    }
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    calculateQuantity() {
      let total_production = this.total_production;
      let result = [];
      this.items.forEach(function(element, index) {
        result[index] = element;
        result[index].calculated_quantity = Math.round(
          (total_production / 100) * element.revenue_percentage
        );
      });
      this.items = result;
    },
    overwriteCustomQuantity() {
      if (!this.total_production) return false;
      let result = [];
      this.items.forEach(function(element, index) {
        result[index] = element;
        result[index].custom_quantity = element.calculated_quantity;
      });
      this.items = result;
    },
    currencyFormatter(number) {
      let currencyFormatter = new Intl.NumberFormat("it-IT", {
        style: "currency",
        currency: "EUR"
      });
      return currencyFormatter.format(number);
    }
  }
};
</script>
