var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',[_c('p',{staticClass:"text-right"},[_c('b-button',{staticClass:"m-2",attrs:{"size":"sm"},on:{"click":_vm.selectAllRows}},[_vm._v("Select all")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":_vm.clearSelected}},[_vm._v("Clear selected")])],1),_c('b-table',{ref:"selectableTable",staticClass:"mt-4",attrs:{"selectable":"","items":_vm.items,"fields":_vm.fields},on:{"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"cell(revenue_amount)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.currencyFormatter(data.item.revenue_amount))+" ")]}},{key:"cell(selected)",fn:function(ref){
var rowSelected = ref.rowSelected;
return [(rowSelected)?[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("✓")]),_c('span',{staticClass:"sr-only"},[_vm._v("Selected")])]:[_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Not selected")])]]}},{key:"cell(custom_quantity)",fn:function(data){return [_c('b-form-input',{staticClass:"w-50",attrs:{"type":"number"},model:{value:(data.item.custom_quantity),callback:function ($$v) {_vm.$set(data.item, "custom_quantity", $$v)},expression:"data.item.custom_quantity"}})]}}]),model:{value:(_vm.visibleRows),callback:function ($$v) {_vm.visibleRows=$$v},expression:"visibleRows"}},[_c('template',{slot:"top-row"},[_c('td',{staticClass:"text-right",attrs:{"colspan":"4"}},[_vm._v("Total Production Lot")]),_c('td',[_c('b-input',{attrs:{"type":"number"},on:{"change":_vm.calculateQuantity,"keyup":_vm.calculateQuantity},model:{value:(_vm.total_production),callback:function ($$v) {_vm.total_production=$$v},expression:"total_production"}})],1),_c('td',[_c('b-button',{attrs:{"size":"sm"},on:{"click":_vm.overwriteCustomQuantity}},[_vm._v(" >> ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm.totalCustomQuantity)+" "),(
              _vm.totalCustomQuantity > 0 &&
                (_vm.differenceToTotalProduction > 0 ||
                  _vm.differenceToTotalProduction < 0)
            )?_c('span',[_vm._v("("),_c('b',{class:_vm.differenceToTotalProduction > 0
                  ? 'text-success'
                  : 'text-danger'},[_vm._v(_vm._s(_vm.differenceToTotalProduction))]),_vm._v(")")]):_vm._e()])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }